.App {
  text-align: center;
}
/* 
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

@import url("https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700");


/* @import "@aws-amplify/ui-react/styles.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap");

:root {
  --amplify-fonts-default-variable: "Figtree";
  --amplify-fonts-default-static: "Figtree";
  --amplify-colors-brand-primary-10: var(--amplify-colors-blue-10);
  --amplify-colors-brand-primary-20: var(--amplify-colors-blue-20);
  --amplify-colors-brand-primary-40: var(--amplify-colors-blue-40);
  --amplify-colors-brand-primary-60: var(--amplify-colors-blue-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-blue-80);
  --amplify-colors-brand-primary-90: var(--amplify-colors-blue-90);
  --amplify-colors-brand-primary-100: var(--amplify-colors-blue-100);
  --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
  --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
  --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
  --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
  --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
  --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
  --amplify-colors-radii-small: 0;
  --amplify-colors-radii-medium: 2px;
  --amplify-colors-radii-large: 4px;
  --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
  --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
  --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

  --amplify-radii-small: 0;
  --amplify-radii-medium: 0;
  --amplify-radii-large: 0;
  --amplify-space-small: 1rem;
  --amplify-space-medium: 1.5rem;
  --amplify-space-large: 2rem;
  --amplify-border-widths-small: 2px;
  --amplify-border-widths-medium: 4px;
  --amplify-border-widths-large: 8px;
} */

@import 'https://code.highcharts.com/css/highcharts.css';

:root {
  .highcharts-markers .highcharts-point-select {
    fill: var(--highcharts-color-8);
    stroke: var(--highcharts-neutral-color-100);
  }

  

  --revelata-paper-background-color: rgba(39, 39, 39, 0.3); /*linear-gradient(180deg, rgba(39, 39, 39, 0.3) 50%, rgba(33, 33, 33, 0.3) 100%); */
  --revelata-paper-border-color: #272727;
  /* --revelata-blue: var(--highcharts-color-0); */
  --revelata-blue: #A9DEFF;
  --brand-main: #4FEAFF;
  --brand-link: #57BEFE;
  --brand-text-secondary: #ACBFD2;
  --brand-analogous: #2CFEE6;
  --brand-analogous-50: #dafffa;
  --brand-complementary: #fe7d2c;
  --highcharts-color-0: var(--brand-main);

  --mui-palette-background-default: #0D0D0D !important; 


  --amplify-colors-background-primary: var(--mui-palette-background-paper) !important;
  --amplify-components-field-label-color: var(--brand-text-secondary) !important;
  --amplify-components-fieldcontrol-border-color: #383838 !important;
  --amplify-components-authenticator-router-border-width: 0 !important;
  --amplify-components-fieldcontrol-focus-border-color: var(--brand-main) !important;
  --amplify-components-button-background-color: var(--brand-main) !important; 
  --amplify-components-button-hover-background-color: #00d7f8!important;

  --amplify-components-button-primary-background-color: var(--brand-main) !important; 
  --amplify-components-button-focus-background-color: var(--brand-main) !important;
  --amplify-components-button-primary-hover-background-color: #00d7f8 !important; 
  --amplify-components-button-primary-focus-background-color: var(--mui-palette-primary-dark) !important; 
  --amplify-components-button-primary-active-background-color: var(--mui-palette-primary-dark) !important; 
  --amplify-components-button-primary-overlay-background-color: var(--mui-palette-primary-dark) !important;
  
  /* --amplify-internal-button-color: var(--brand-main) !important;
  --amplify-internal-button-background-color: var(--brand-main) !important;  */
  --amplify-internal-button-background-color: var(--brand-main) !important; 
  /* --amplify-components-button-background-color: var(--mui-palette-grey-500) !important; */

  --amplify-components-passwordfield-button-color: var(--mui-palette-primary-contrastText) !important;
  --amplify-components-button-link-color: var(--brand-main) !important;

  /* --amplify-components-button-focus-background-color: var(--brand-main) !important; */
  --amplify-components-input-color: var(--brand-text-secondary) !important; 
  --amplify-components-text-error-color: var(--mui-palette-error-main) !important;
  --amplify-components-heading-color: var(--mui-palette-text-primary) !important;

  --amplify-components-authenticator-router-background-color: var(--revelata-paper-background-color) !important;
  --amplify-components-tabs-item-active-color: var(--brand-main) !important; 
  --amplify-components-tabs-item-active-border-color: var(--brand-main) !important; 

}

:root[data-mui-color-scheme="dark"] {
  --mui-palette-primary-main: #4FEAFF;
}

/* :root [data-amplify-router] {
  background-image: var(--mui-overlays-24) !important;
  box-shadow: var(--mui-shadow-24) !important;
  color: var(--mui-palette-text-primary);
  border-radius: var(--mui-shape-borderRadius) !important; 
} */

.amplify-input {
  font-size: 1rem !important;
  border-radius: var(--mui-shape-borderRadius) !important; 
  color: var(--brand-main);
}

.amplify-button {
  font-family: Figtree !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  border-radius: var(--mui-shape-borderRadius) !important; 
  text-transform: uppercase !important;
  color: var(--mui-palette-primary-contrastText) !important; 
}

.amplify-text {
  display: block;
  color: var(--brand-text-secondary) !important;
}


.amplify-tabs-item[data-state=active] {
  color: var(--brand-main) !important;
  border-color: var(--brand-main) !important;
  /* background-color: var(--revelata-paper-background-color) !important; */
  transition-property: none;
} 

.amplify-tabs-item[data-state=inactive] {
  color: #00d7f8 !important;
  border-color: #131415 !important;
  background-color: #040505 !important;
  transition-property: none;
}

.amplify-button--link{
  font-family: Figtree !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  border-radius: var(--mui-shape-borderRadius) !important; 
  text-transform: uppercase !important;
  color: var(--brand-main) !important;
}

.amplify-button--link:hover {
  text-decoration: underline;
  background-color: #121212;
}

.amplify-button--disabled {
  font-family: Figtree !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
  border: 0 !important;
  border-radius: var(--mui-shape-borderRadius) !important; 
  text-transform: uppercase !important;
  color: var(--mui-palette-action-disabled) !important;
  box-shadow: var(--mui-shadows-0) !important;
  background-color: var(--mui-palette-action-disabledBackground) !important;
}

.amplify-button--primary {
  font-family: Figtree !important;
  font-weight: medium !important;
  font-size: 1.125rem !important;
  line-height: 1.75 !important;
  border-radius: var(--mui-shape-borderRadius) !important; 
  text-transform: uppercase !important;
  color: var(--mui-palette-primary-contrastText) !important; 
}
/* :root, [data-amplify-theme] {
  --amplify-colors-background-primary: rgba(0, 0, 0, 0);
} */

/* .data-amplify-authenticator {
  background-color: var(--mui-palette-background-default);
} */

.revelata-highcharts-font {
  font-weight: 400;
  font-family: Figtree;
  font-size: 1rem;  
}

.highcharts-dark {
  --highcharts-background-color: #00000000 !important;
}

.highcharts-point {
  stroke-width: 0px;
}

